.ReactModalPortal >div {
    opacity:0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
    position:fixed;
    z-index: 3;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow-y: hidden;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 1;
}

.modal {
    background: teal;
    color: white;
    max-width: 50rem;
    outline: none;
    padding: 2em;
    text-align: center;
    position: absolute;
    z-index: 3;
}

.map_modal {
    max-width: 50rem;
    outline: none;
    padding: 0em;
    text-align: center;
    position: absolute;
    z-index: 3;
}

.videomodal {
    background: black;
    color: white;
    max-width: 50rem;
    outline: none;
    padding: 0em;
    text-align: center;
}

.vrpopupmodal {
    color: white;
    max-width: 50rem;
    outline: none;
    padding: 0em;
    text-align: center;
}

.modal__tile{
    margin: 0 0 0 0;
    position: absolute;
    z-index: 3;
}

.modal__body{
    font-size: 3em;
    font-weight: 300;
    margin: 0 0 0 0;
    word-break: break-all;
    position: absolute;
    z-index: 3;
}

.overlay {
    background-color: black;
    overflow-y: hidden;
}

.overlay-fade {
    background-color: rgba(0,0,0,0.5);
    overflow-y: hidden;
}

.divider{
    width:0.4em;
    height:auto;
    display:inline-block;
}